import React from 'react';
import styled from 'styled-components';
import H2 from '../typo/H2';
import H3 from '../typo/H3';
import P from '../typo/P';

const Content = P.withComponent('div');

const SuccessfulIsP = ({title, copy}) => (
  <section className="flight-block gray-style">
    <div className="container">
      <H2>{title || ''}</H2>
      <Content dangerouslySetInnerHTML={{__html: copy}}/>
    </div>
  </section>
);

export default SuccessfulIsP;
