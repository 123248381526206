import React, {Fragment} from 'react';
import {Link} from 'gatsby';
import Media from 'react-media';
import styled from 'styled-components';
import {Carousel as CarouselComponent} from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import bg from './bg-intro.jpg';

const Carousel = styled(CarouselComponent)`
  background: #e20074;
  height: 100%;
  font-size: 30px;
  line-height: 1.1;
  color: white;
  text-transform: uppercase;
  min-height: 390px;
  height: 80vw;
  .carousel {
    height: 100%;
  }
  .slider-wrapper {
    height: 100%;
  }
  .slider {
    height: 100%;
  }

  h2 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
  }
  .btn {
    font-size: 14px;
    padding: 11px 20px 9px;
  }
  @media (min-width: 640px) {
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 24px;
    }
    .btn {
      font-size: 18px;
      padding: 13px 20px 11px;
    }
  }
  @media (min-width: 768px) {
    min-height: auto;
    height: 30vw;

    h2 {
      font-size: 20px;
    }
    p {
      font-size: 18px;
    }
    .btn {
      font-size: 14px;
      padding: 11px 20px 9px;
    }
  }
  @media (min-width: 960px) {
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 24px;
    }
    .btn {
      font-size: 18px;
      padding: 13px 20px 11px;
    }
  }
  @media (min-width: 1080px) {
    font-size: 30px;
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 30px;
    }
    .btn {
      padding: 13px 40px 11px;
    }
  }
  @media (min-width: 1500px) {
    font-size: 40px;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 36px;
    }
  }
`;

const Slide = styled.div`
  background-size: cover;
  background-position: 0 0;
  height: 100%;

  
  align-items: flex-end;
  justify-content: center;
  @media (min-width: 768px) {
    background-position: 100% 50%;
    align-items: center;
    justify-content: flex-start;
  }
  
  &.slide__desktop {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }
  &.slide__mobile {
    display: flex;
    @media (min-width: 768px) {
      display: none !important;
    }
  }
`;

const ContentWrapper = styled.div`
  max-width: 1194px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  padding-bottom: 30px;
  align-items: flex-end;

  @media (min-width: 640px) {
    padding-bottom: 60px;
  }

  @media (min-width: 768px) {
    align-items: center;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 80px;
  }
`;

const Content = styled.div`
  max-width: none;
  text-align: left;
  width: 100%;

  h2 {
    margin-bottom: 6px;
    color: inherit;
  }
  p {
    margin-bottom: 30px;
    text-transform: none;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const ContentNode = ({s}) => (
  <ContentWrapper className="container">
    <Content>
      <h2>{s.title}</h2>
      <p>{s.copy}</p>
      <Link className="btn" to={s.url}>
        {s.linkLabel}
      </Link>
    </Content>
  </ContentWrapper>
);

const HomeSlider = ({content}) => (
  <Carousel
    showStatus={false}
    showIndicators={true}
    showArrows={true}
    showThumbs={false}
    className="big-gallery slick-initialized slick-slider slick-dotted">
    {content.map(({node: s}, k) => (
      <Fragment>
        <Slide
          className="slide__mobile"
          style={{backgroundImage: `url(${s.mobileImage.url})`}}>
          <ContentNode s={s} />
        </Slide>
        <Slide
          className="slide__desktop"
          style={{backgroundImage: `url(${s.desktopImage.url})`}}>
          <ContentNode s={s} />
        </Slide>
      </Fragment>
    ))}
  </Carousel>
);

export default HomeSlider;
