import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";
import HomeSlider from "./../components/Home/HomeSlider";
import TextSlider from "./../components/Home/TextSlider";
import Isp from "./../components/Home/ISP";
import NewsTeaser from "./../components/Home/NewsTeaser";
import Performance from "./../components/Home/Performance";
import SuccessfulIsp from "./../components/Home/SuccessfulIsp";
import EanTeaser from "./../components/Home/EanTeaser";
import Downloads from "./../components/Downloads";
import Layout from "./../components/Layout";
//import { HelmetDatoCms } from 'gatsby-source-datocms'

class IndexPage extends React.Component {
  render() {
    const { data, location } = this.props;

    if (!data) return <p />;
    return (
      <Layout location={location}>
        <Fragment>
          <HomeSlider content={data.homeSlider.edges} />
          <TextSlider content={data.textSlider.edges} />
          <Isp
            preinpost={data.preinpost.preinpost}
            intro={data.preinpost.block1IspIntro}
            title={data.preinpost.block1IspTitle}
          />
          <NewsTeaser content={data.news.edges} />
          {/* <Performance */}
          {/*   title={data.home.airlinesBlock[0].title} */}
          {/*   subline={data.home.airlinesBlock[0].subline} */}
          {/*   intro={data.home.airlinesBlock[0].intro} */}
          {/*   airlines={data.home.airlinesBlock[0].airlinesList} */}
          {/* /> */}
          <SuccessfulIsp
            title={data.home.successfulIspTitle}
            copy={data.home.successfulIspCopy}
          />
          <EanTeaser
            title={data.home.eanTeaserTitle}
            copy={data.home.eanTeaserCopyNode.childMarkdownRemark.html}
            image={data.home.eanTeaserImage.url}
          />
          {/*<Downloads /> */}
        </Fragment>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    preinpost: datoCmsHomePage {
      block1IspTitle
      block1IspIntro
      preinpost {
        title
        image {
          url
        }
        intro
        benefitsTitle
        benefit1
        benefit1Body
        benefit2
        benefit2Body
      }
    }
    home: datoCmsHomePage {
      airlinesBlock {
        title
        subline
        intro
        airlinesList {
          url
          alt
          width
          height
        }
      }
      successfulIspTitle
      successfulIspCopy
      eanTeaserTitle
      eanTeaserCopyNode {
        childMarkdownRemark {
          html
        }
      }
      eanTeaserImage {
        url
      }
    }
    news: allDatoCmsNews(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          teaser
          date
          dateString
          slug
          online
          postImage {
            url
            fixed(width: 442, height: 295) {
              ...GatsbyDatoCmsFixed_noBase64
            }
          }
        }
      }
    }
    homeSlider: allDatoCmsHomeSlider {
      edges {
        node {
          title
          copy
          linkLabel
          url
          desktopImage {
            url
          }
          mobileImage {
            url
          }
        }
      }
    }
    textSlider: allDatoCmsTextSlider {
      edges {
        node {
          headline
          body
        }
      }
    }
  }
`;
