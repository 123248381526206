import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import H2 from '../typo/H2';
import H3 from '../typo/H3';
import P from '../typo/P';

const Content = P.withComponent('div');

const Button = styled(Link)`
  font-size: 22px;
  padding: 0.6em;
`;

const EanTeaser = ({title, copy, image}) => (
  <section className="network-block">
    <div className="container">
      <h2>{title}</h2>
      <div className="text-with-image">
        <a
          href="#"
          className="image"
          style={{background: `url(${image}) no-repeat`}}
        />
        <Content className="content" dangerouslySetInnerHTML={{__html: copy}} />
      </div>
      <footer>
        <Button to="/ean" className="btn">
          see the full EAN story
        </Button>
      </footer>
    </div>
  </section>
);

export default EanTeaser;
