import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import H2 from "../typo/H2";
import H3 from "../typo/H3";
import P from "../typo/P";

const TextSlider = ({ content, className }) => (
  <div className={`container ${className}`}>
    <section className="text-gallery">
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={false}
        infiniteLoop={true}
      >
        {content.map((c, k) => (
          <div key={k}>
            {c.node.headline ? <H2>{c.node.headline}</H2> : null}
            {c.node.subline ? <H3>{c.node.subline}</H3> : null}
            {c.node.body ? <P>{c.node.body}</P> : null}
          </div>
        ))}
      </Carousel>
    </section>
  </div>
);

export default TextSlider;
