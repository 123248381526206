import React from 'react';
import styled from 'styled-components';
import P from '../typo/P';

const Title = styled.h3`
  font-size: 36px;
  line-height: 60px;
  @media (min-width: 640px) {
    font-size: 40px;
  }
`;
const IconWrap = styled.span`
  margin-right: 16px;
`;

const Icon = styled.span`
  width: 60px;
  height: 60px;
  border: 6px solid #e20074;

  svg {
    width: 80%;
  }
  svg.icon-pin {
    width: 60%;
  }
`;

const Content = styled.div`
  padding: 25px 7px 5px;
`;

const ImgMobile = styled.div`
  width: 100%;
  padding-top: 60.25%;
  background-size: cover;
  background-repeat: no-repeat;
`;

const OverlayIcon = styled.span`
  border-width: 10px;
  width: 160px;
  height: 160px;
  @media (min-width: 640px) {
    width: 320px;
    height: 320px;
  }
`;

const Intro = styled.p`
  padding: 0 2em;
  font-size: 24px;
  margin-bottom: 1em;
`;

const H4 = styled.h4`
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0 2em;
  font-size: 22px;

  @media (min-width: 640px) {
    font-size: 28px;
  }
`;

const H5 = styled.h5`
  font-size: 20px;
  @media (min-width: 640px) {
    font-size: 26px;
  }
`;

const Benefit = styled.li`
  padding-left: 57px;
  position: relative;
  p:first-of-type {
    text-transform: uppercase;
    color: #e20074;
    font-weight: 900;
    margin-bottom: 0.5em;
  }
  p {
    padding-left: 0;
  }
`;

const Checkmark = styled.span`
  width: 37px;
  height: 37px;
  border-width: 5px;
  &:before {
    top: -8px;
    left: 18px;
    width: 6px;
    height: 32px;
    border-radius: 2px;
  }
  &:after {
    top: 7px;
    left: 4px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
  }
`;

const ISPColumn = ({icon, content}) => (
  <li>
    <div className="image hidden-mobile">
      <img
        src={content.image.url}
        alt={content.image.title}
        width="442"
        height="432"
      />
      <span className="icon-image">
        <svg className={icon}>
          <use xlinkHref={`#${icon}`} />
        </svg>
      </span>
    </div>
    <a href="#" className="slide-opener hidden-desktop">
      open slide
    </a>
    <Content className="content">
      <Title>
        <IconWrap className="icon-wrap">
          <Icon className="icon">
            <svg className={icon}>
              <use xlinkHref={`#${icon}`} />
            </svg>
          </Icon>
        </IconWrap>
        {content.title}
      </Title>
      <strong className="subtitle">{content.subline}</strong>
      <P>{content.intro}</P>
      <div className="slide">
        <ImgMobile
          className="image hidden-desktop"
          style={{backgroundImage: `url(${content.image.url})`}}>
          <OverlayIcon className="icon-image">
            <svg className={icon}>
              <use xlinkHref={`#${icon}`} />
            </svg>
          </OverlayIcon>
        </ImgMobile>
        <H4>{content.benefitsTitle}</H4>
        <ul className="benefits-list">
          <Benefit>
            <Checkmark className="icon-checked" />
            <P>{content.benefit1}</P>
            <P>{content.benefit1Body}</P>
          </Benefit>
          <Benefit>
            <Checkmark className="icon-checked" />
            <P>{content.benefit2}</P>
            <P>{content.benefit2Body}</P>
          </Benefit>
        </ul>
      </div>
    </Content>
  </li>
);

export default ISPColumn;
