import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link'
import ISPColumn from './ISPColumn';
import H2 from '../typo/H2';
import H3 from '../typo/H3';
import P from '../typo/P';

const Button = styled(Link)`
  font-size: 22px;
  padding: 0.6em;
`;

const Isp = ({title, intro, preinpost}) => (
  <section className="advantages-block pink-style">
    <div className="container">
      <header>
        <H2 dangerouslySetInnerHTML={{__html: title}} />
        <P color="white">{intro}</P>
      </header>
      <ul className="advantages-list">
        <ISPColumn icon="icon-home" content={preinpost[0]} />
        <ISPColumn icon="icon-plane" content={preinpost[1]} />
        <ISPColumn icon="icon-pin" content={preinpost[2]} />
      </ul>
      <footer>
        <Button to="/isp-story" className="btn">
          see full ISP story
        </Button>
      </footer>
    </div>
  </section>
);

export default Isp;
